<template>
  <div class="paid-payments">
    <template v-if="payments.length < 1">
      Oops! No Paid Payments at the moment.
    </template>
    <template v-else>
      <div class="row">
        <template v-for="payment in payments">
          <div class="col-12 col-lg-6" :key="payment._id" v-if="payment._id">
            <single-payments :payment="payment" />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SinglePayment from "@/view/sub-pages/payments/SinglePayment";
import { GET_PAID_PAYMENTS } from "@/core/services/store/payments.module";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}paid-payments`,
  components: {
    "single-payments": SinglePayment
  },
  mounted() {
    this.$store.dispatch(GET_PAID_PAYMENTS);
  },
  computed: {
    ...mapState({
      payments: currentState => currentState.payments.paidPayments
    })
  }
};
</script>

<style lang="scss" scoped></style>
