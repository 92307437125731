<template>
  <div class="single-payment">
    <div class="single-payment__details">
      <div class="single-payment__rider-image">
        <img :src="payment.rider.profileImage" alt="RiderImage" />
      </div>
      <div class="single-payment__order-span">
        <div
          class="single-payment__order-span__item single-payment__order-span__item--bold"
        >
          {{ payment.rider.name }}
        </div>
        <div class="single-payment__order-span__item">
          Orders: {{ payment.orders.length }}
        </div>
      </div>
      <div class="single-payment__order-span">
        <div class="single-payment__order-span__item text-center">
          <button
            class="baskyt-btn"
            :class="[
              payment.status === 'verified' ? 'baskyt-btn--green' : '',
              disableAll ||
              payment.status === 'verified' ||
              payment.status === 'rejected' ||
              payment.status === 'pending'
                ? 'baskyt-btn--disabled'
                : ''
            ]"
            :disabled="
              disableAll ||
                payment.status === 'verified' ||
                payment.status === 'rejected' ||
                payment.status === 'pending'
            "
            @click="receivePayment"
          >
            {{
              payment.status !== "verified" || payment.status === "rejected"
                ? "Receive"
                : "Paid"
            }}
          </button>
        </div>
        <div
          class="single-payment__order-span__item single-payment__order-span__item--black text-center"
        >
          PKR {{ payment.amount }}
        </div>
      </div>
    </div>
    <div class="single-payment__details">
      <a
        @click="paymentDetails"
        href="javascript:void(0);"
        class="single-payment__red-link"
        >View Details</a
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { RECEIVE_PAYMENT } from "@/core/services/store/payments.module";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}single-payment`,
  props: {
    payment: Object,
    disableAll: Boolean
  },
  methods: {
    paymentDetails() {
      const { _id: id } = this.payment;
      this.$router.push(`/payments/Detail/${id}`);
    },
    async receivePayment() {
      const orders = this.payment.orders.map(o => o.order._id);
      const payload = {
        payment: this.payment._id,
        rider: this.payment.rider._id,
        orders
      };
      this.$emit("start-processing");
      await this.$store.dispatch(RECEIVE_PAYMENT, payload);
      this.$emit("end-processing");
    }
  }
};
</script>

<style lang="scss" scoped></style>
