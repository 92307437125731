<template>
  <div class="pending-payments">
    <div class="row">
      <template v-for="payment in payments">
        <div
          class="col-12 col-lg-6"
          :key="payment._id"
          v-if="payment._id"
          v-show="disableAll === 'hide'"
        >
          <single-payments
            :payment="payment"
            @start-processing="toggleDisable(false)"
            @end-processing="toggleDisable(true)"
            :disableAll="disableAll === 'show'"
          />
        </div>
      </template>
      <template>
        <baskyt-progress v-show="disableAll === 'show'">
          <template v-slot:text>
            Processing
          </template>
        </baskyt-progress>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SinglePayment from "@/view/sub-pages/payments/SinglePayment";
import { GET_PENDING_PAYMENTS } from "@/core/services/store/payments.module";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}pending-payments`,
  components: {
    "single-payments": SinglePayment
  },
  data() {
    return {
      disableAll: "hide"
    };
  },
  methods: {
    async refresh() {
      await this.$store.dispatch(GET_PENDING_PAYMENTS);
    },
    async toggleDisable(x) {
      this.disableAll = x ? "hide" : "show";
      if (this.disableAll === "hide") {
        await this.refresh();
      }
    }
  },
  async mounted() {
    await this.refresh();
  },
  computed: {
    ...mapState({
      payments: currentState => currentState.payments.pendingPayments
    })
  }
};
</script>

<style lang="scss" scoped></style>
