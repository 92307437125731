<template>
  <div class="payments">
    <div class="payments__nav">
      <ul class="mobile-tabs__nav">
        <li>
          <router-link to="/payments/pending">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 0 ? 'mobile-tabs__title--active' : ''"
            >
              <span class="mobile-tabs__title__count">0</span>
              <span>Pending</span>
            </div></router-link
          >
        </li>
        <li>
          <router-link to="/payments/paid">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 1 ? 'mobile-tabs__title--active' : ''"
            >
              <span>Paid</span>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="mobile-tabs__indicator-container">
        <div
          class="mobile-tabs__indicator"
          :class="
            orderActiveTab === 1
              ? 'mobile-tabs__indicator--right'
              : 'mobile-tabs__indicator--left'
          "
        ></div>
      </div>
    </div>

    <b-tabs
      class="mobile-tabs"
      nav-class="d-none"
      content-class="border-red"
      v-model="orderActiveTab"
    >
      <b-tab>
        <template v-if="orderActiveTab === 0">
          <div class="payments__content">
            <pending-payments />
          </div>
        </template>
      </b-tab>

      <b-tab>
        <template v-if="orderActiveTab === 1">
          <div class="payments__content">
            <paid-payments />
          </div>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PendingPayments from "@/view/sub-pages/payments/PendingPayments";
import PaidPayments from "@/view/sub-pages/payments/PaidPayments";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}payments`,
  components: {
    "pending-payments": PendingPayments,
    "paid-payments": PaidPayments
  },
  data() {
    return {
      orderActiveTab: 0
    };
  },
  methods: {},
  mounted() {
    const { id } = this.$route.params;
    if (id === "pending") {
      this.orderActiveTab = 0;
    } else if (id === "paid") {
      this.orderActiveTab = 1;
    } else {
      this.orderActiveTab = 0;
    }
  },

  watch: {
    $route(to) {
      const { id } = to.params;
      if (id === "pending") {
        this.orderActiveTab = 0;
      } else if (id === "paid") {
        this.orderActiveTab = 1;
      } else {
        this.orderActiveTab = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
