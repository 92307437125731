var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"single-payment"},[_c('div',{staticClass:"single-payment__details"},[_c('div',{staticClass:"single-payment__rider-image"},[_c('img',{attrs:{"src":_vm.payment.rider.profileImage,"alt":"RiderImage"}})]),_c('div',{staticClass:"single-payment__order-span"},[_c('div',{staticClass:"single-payment__order-span__item single-payment__order-span__item--bold"},[_vm._v(" "+_vm._s(_vm.payment.rider.name)+" ")]),_c('div',{staticClass:"single-payment__order-span__item"},[_vm._v(" Orders: "+_vm._s(_vm.payment.orders.length)+" ")])]),_c('div',{staticClass:"single-payment__order-span"},[_c('div',{staticClass:"single-payment__order-span__item text-center"},[_c('button',{staticClass:"baskyt-btn",class:[
            _vm.payment.status === 'verified' ? 'baskyt-btn--green' : '',
            _vm.disableAll ||
            _vm.payment.status === 'verified' ||
            _vm.payment.status === 'rejected' ||
            _vm.payment.status === 'pending'
              ? 'baskyt-btn--disabled'
              : ''
          ],attrs:{"disabled":_vm.disableAll ||
              _vm.payment.status === 'verified' ||
              _vm.payment.status === 'rejected' ||
              _vm.payment.status === 'pending'},on:{"click":_vm.receivePayment}},[_vm._v(" "+_vm._s(_vm.payment.status !== "verified" || _vm.payment.status === "rejected" ? "Receive" : "Paid")+" ")])]),_c('div',{staticClass:"single-payment__order-span__item single-payment__order-span__item--black text-center"},[_vm._v(" PKR "+_vm._s(_vm.payment.amount)+" ")])])]),_c('div',{staticClass:"single-payment__details"},[_c('a',{staticClass:"single-payment__red-link",attrs:{"href":"javascript:void(0);"},on:{"click":_vm.paymentDetails}},[_vm._v("View Details")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }